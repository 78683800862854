import React from 'react'
import { Link, graphql, navigate } from 'gatsby'
import Helmet from 'react-helmet'

import Layout from '../components/layout'
import styles from "./welcome.module.scss"
import stemLogo from '../assets/stem_logo_black.svg'

class WelcomePage extends React.Component {

  doNavigate = event => {
    navigate('/login' + window.location.search);
  }

  render() {
    const defaultMetadata = this.props.data.datoCmsSite.globalSeo
    const pageTitle = 'Üdvözöljük ' + defaultMetadata.titleSuffix
    const pageDescription = defaultMetadata.fallbackSeo.description

    return (
      <Layout location={this.props.location}  readOnlyAPIToken={this.props.data.site.siteMetadata.readOnlyAPIToken}>

        <Helmet
          htmlAttributes={{ lang: 'hu' }}
          meta={[{ name: 'description', content: pageDescription }]}
          title={pageTitle}
        />

        <div onClick={this.doNavigate} className={styles.logo}>
          <img src={stemLogo} alt={`Belépés`}/>
        </div>

      </Layout>
    )
  }
}

export default WelcomePage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        productionMode
        fullAPIToken
        readOnlyAPIToken
      }
    }
    datoCmsSite {
      globalSeo {
        siteName
        titleSuffix
        fallbackSeo {
          title
          description
        }
      }
    }
  }
`
